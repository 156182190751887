@import 'resources/styles/variables';

.header-container {
  padding-top: 32px;
  border-bottom: solid 1px $color-off-white;

  #main-nav {
    z-index: 999;
  }

  .top-menu {

    .nav-item {
      color: $color-cool-grey;
      margin-left: 32px;

      a {
        color: $color-cool-grey;
      }
    }
  }

  .navbar {
    .nav-item {
      font-size: 18px;
      color: $color-cool-grey !important;

      .nav-link {
        color: $color-cool-grey !important;
        padding: 24px 16px !important;
      }

      .active {
        color: $color-blue !important;
        border-bottom: solid 1px $color-blue;
        font-weight: bold;
      }

      .dropdown-item.active {
        color: #fff !important;
      }
    }
  }

  .user-menu {
    .nav-item {
      display: flex;
      align-items: center;
      padding: 16px 12px;
      position: relative;

      img {
        height: 23px;
        width: 23px;
      }
      .nav-link {
        color: $color-cool-grey !important;
      }
    }

    .dropdown-menu {
      left: 100% !important;
      right: 100%;
      top: 0;
      border: none;
      -webkit-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
      -moz-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
      box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    }

    .dropdown-item {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .loan-menu .dropdown-menu {
    top: 48px;
    border: none;
    -webkit-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    -moz-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
  }

  .loan-menu.show {
    background-color: #ffffff;
    -webkit-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    -moz-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);

    .nav-link {
      color: $color-blue !important;
      font-weight: bold;
      position: relative;
    }
  }

  .dropdown-item {
    padding-left: 16px;
    padding-right: 16px;
  }

  p.dropdown-item {
    padding-left: 16px !important;
  }

  .notifications-badge {
    width: 16px;
    height: 16px;
    color: #ffffff;
    font-size: 10px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: -9px;
    top: -10px;
    padding-top: 1px;
    font-weight: bold;
    background-color: $color-red;
  }

  .cart-badge {
    width: 16px;
    height: 16px;
    color: #ffffff;
    font-size: 10px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: -8px;
    top: -10px;
    padding-top: 1px;
    font-weight: bold;
    background-color: $color-red;
  }

  .no-cart-item {
    font-size: 12px;
    font-weight: bold;
  }
}

.cart-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px $color-light-grey;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .kit-image {
    width: 64px;
    height: 64px;
    margin-right: 15px;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }

  .kit-detail {
    .kit-name {
      font-weight: bold;
    }
    .kit-date {
      font-weight: 300;
    }
    .kit-delete {
      color: $color-blue;
      font-size: 12px;
      border: none;
      background: transparent;
      padding: 0 !important;

      span {
        padding-top: 5px;
      }
    }
  }
}

.header-container .user {
  .dropdown-menu {
    width: 200px;
    padding: 15px;
    right: -61px;
  }
}

.header-container .cart {
  position: relative;

  .dropdown-menu {
    width: 306px;
    right: -32px !important;
    transform: translate3d(-32px, 48px, 0px) !important;

    .cart-container {
      max-height: 200px;
      overflow-y: auto;
    }
  }
}

.not-logged-in-loan-list-button {
  background-color: $color-blue;
  color: #FFFFFF;
  font-weight: bold;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu li {
  position: relative;
  }
  .dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  }
  .dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
  }
  .dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
  top: 5px
  }

  .dropdown.dropdown-submenu:hover{
    display: block;
    top: 5px
  }

@media(max-width: 1920px) {
  #loginModal .modal-dialog {
    margin-top: 160px;
  }
}

@media(max-width: 1280px) {

  .header-container .sticky .user-menu .nav-item {
    margin-left: 3px !important;
  }

  .header-container .sticky .navbar .nav-item {
    margin-right: 16px !important;
  }
}

@media(max-width: 1024px) {
  .header-container .navbar .nav-item .nav-link {
    padding: 24px 9px;
  }

  .header-container .dropdown-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  .header-container {
    padding-top: 0 !important;
  }
}

.toolbar-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.back-next-buttons {
  display: flex;
  align-items: center;
}

.back-next-buttons button {
  border: none;
  background-color: white;
}

.btn-back {
  color: #0088D0;
  font-size: 38px;
}

.next {
  color: #0088D0;
  font-size: 38px;
}

.rbc-time-header-content {
  background-color: #F8F8F8;
}

.rbc-day-slot .rbc-time-slot {
  border-top: none !important;
  height: 64px;
}

.rbc-header{
  border-bottom: none !important;
  display: flex;
  justify-content: center;
}

.rbc-timeslot-group {
  min-height: 64px !important;
}

.rbc-today {
  background-color: #fff !important;
}

//  .rbc-today {
  //   color: white !important;
  // }
  
.rbc-time-header-content {
  .rbc-today{
    background-color: #0088D0 !important;
    color: #FFFFFF;
  }
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: #edf2f5 !important;
  color: black !important;
  border: none;
}

.rbc-event .rbc-event-allday{
  background-color: #edf2f5 !important;
  color: black !important;
  border: none;
}

.rbc-event {
  border: none !important;
}

.rbc-day-slot .rbc-events-container{
  margin-right: 0px !important;
}

.rbc-row {
  .rbc-event-allday{
  background-color: #F3F4F680 !important;
  }
}

.rbc-event-label{
  display: none !important;
}



.rbc-button-link {
  span{
    content: '';
    white-space: normal;
    display: block;
    width: 30px;
    position: relative;
  }
}


.rbc-header {
  height: 67px;
  // font-family: 'ZEISS Frutiger Next W07 Medium' !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.rbc-row{
  min-height: 0 !important;
}
.rbc-time-view .rbc-allday-cell {
  height: 0 !important;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #4AB300;
  border-radius: 50%;
}

.app-datepicker .current-date-my-schudle {
  margin-left: 8px !important;
  font-size: 16px;
  font-weight: bold;
}

.label-date{
  width: 50%;
}

.rbc-day-slot .rbc-event-content{
  word-wrap: normal !important;
  font-size: 15px !important;
}

.rbc-time-header {
  position: sticky;
  top: 0;
  z-index: 9;
}

.edit-delete-buttton-adjustment{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -27px;
}

.edit-delete-icon-without-border{
  border: none;
  background-color: white;
}

.schedule-recurring-loader {
  width: 33px;
  height: 33px;
  border: 5px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  .view-Title-adjustment{
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

.demo-slot-booking{
  border-left: 4px solid #0088D0 !important;
  background: rgba(0, 136, 208, 0.09) !important;
}

.new-navbar{
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.new-nav-item {
  background-color: #FFFFFF;
  height: 100vh;
  width:300px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  position: fixed;
  left: -100%;
  transition: 2s;
  z-index: 9999;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: black;
  font-weight: 500;
  font-size: 16px;
  top: 0;
}


.new-navbar:hover + .new-nav-item,
.new-nav-item:hover {
  left: 0;
  transition: 0.7s;
}

.line-for-menu:hover + .toggle-menu-bar .new-nav-item {
    left: 0;
    transition: 0.7s;
  }


.new-nav-text{
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  margin: 5px;
}

.hide-nav-bar{
  left: -100% !important;
}

.nav-parent{
  list-style-type: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  .dropdown-item{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.nav-child{
  display: none;
  position: absolute !important;
  top: -0.3% !important;
  left: 94% !important;
  height:100vh;
  width:240px;
  transform: none !important;
}

.right-header-adjusment{
  display: flex;
  gap: 47px;
  align-items: center;
  justify-content: center;
  color: #FFFFFF !important;
  font-weight: 300;
}

.homepage-logo-color-adjust{
  background: linear-gradient(to bottom, #ffffff,  #4868B1);
}

.set-active-color{
  color: #007bff !important;
}

.set-non-active-color{
  color: black !important;
}

.adjust-person-menu-dropdowm{
  transform: none !important;
  top: 60px !important;
}

.divider-line{
  border-top: 1.3px solid #CCCDCD;
  margin-inline: 0.85rem;
  margin-top: 1.25rem;
}

.logged-user-name{
  margin-left: 10px !important;
  font-size: 16px;
  font-weight: 600;
  margin-top: 3px !important;
}

.logged-in-badge {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: #4AB300;
  position: absolute;
  top: 20px;
  right: 0;
}

.line-for-menu{
  // border: 2px solid #0000000f;
  height: 100vh;
  position: fixed;
  left: 5px;
  top: 0px;
  z-index: 999;
  width: 20px;
  background: transparent;
  padding: 10px;
}

.line-for-menu div{
  border: 2px solid #0000000f;
  height: 100vh;
}
.nav-parent:hover{
  background-color: #e9ecef;
}

.submenu-optipons{
  display: none;
  top: 5px
}

.main-option:hover{
  .submenu-optipons{
    display: block;
  top: 5px
  }
}