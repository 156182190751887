.popover {
  &-wrapper {
    position: relative;
    display: inline-block;
  }

  &-content {
    position: fixed;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 99999;
    min-width: 100px;
    animation: popover-fade 0.2s ease-in-out;
  }

//   &-arrow {
//     position: absolute;
//     width: 8px;
//     height: 8px;
//     background: white;
//     border: 1px solid rgba(0, 0, 0, 0.1);
//     transform: rotate(45deg);
//     z-index: 99998;
//   }

  &-inner {
    position: relative;
    z-index: 99999;
    background: white;
  }
}

@keyframes popover-fade {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
