.calendar-slot {
  border-radius: 0;
  font-family: 'FrutigerNextRegular' !important;

  .header {
    background-color: #f2bdff;
    color: #000;
    /* padding: 3px 5px; */
    font-size: 6px;
    /* line-height: 9.86px; */
    padding-inline: 5px;
  }

  .request-type1 {
    background-color: #000000 !important;
  }
  .request-type1-border {
    border-bottom: 1px solid;
    border: 2px solid #000000 !important;
    border-top: 0 !important;
    background-color: #f2bdff0d !important;
  }
  .request-type2 {
    background: repeating-linear-gradient(-45deg, #ff0000, #ff0000 2px, #fff 2px, #fff 5px) !important;
  }

  .request-type2-border {
    border: none !important;
    background-color: rgba(207, 54, 82, 0.05) !important;
  }

  .request-type3 {
    background-color: #f4b084 !important;
  }
  .request-type3-border {
    border: 3px solid #ff0000 !important;
    border-top: 0 !important;
    background-color: #f4b0840d !important;
  }

  .slot-name-container {
    box-sizing: border-box;
    /* background-color: #00b0f00d; */
    /* border: 3px solid #00b050; */
    border-top: 0;
    color: #000;
    font-size: 7px;
    font-weight: bold;
    /* padding: 5px; */
    flex-wrap: wrap;
    text-align: center;
  }

  .comment-slots {
    white-space: nowrap;      // Prevents wrapping to a new line
    overflow: hidden;         // Hides overflowing text
    text-overflow: ellipsis;  // Adds "..." when text overflows
    display: block;           // Ensures proper block rendering
    max-width: 100%;          // Prevents overflow beyond container
    font-size: 12px;
    color: #000;
    height: 100%;
  }

}

.header3-text {
  border: none;
  position: relative;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  .slot-header-date {
    margin-top: 8px;
    font-size: 14px;
    font-family: 'FrutigerNextRegular' !important;
    text-align: left;
    margin-left: 1.5rem;
    font-weight: 400 !important;
    position: absolute;
    width: 1000px;
    top: 0;
  }

  .month-slot-header{
    margin-top: 20px;
    font-size: 12px;
    font-family: 'FrutigerNextRegular' !important;
    text-align: center;
    font-weight: 400 !important;
    /* position: absolute; */
    width: 100%;
  }
  .month-week-text-display{
    margin-top: 0px;
    font-size: 14px;
    font-family: 'FrutigerNextRegular' !important;
    text-align: left;
    font-weight: 400 !important;
    position: absolute;
    width: 1000px;
    top: 0;
    margin-left: 4px;
  }

  .header-today-text {
    color: #0088d0;
    font-weight: 500 !important;
  }

  .header-week-view-time {
    flex: 1;
    text-align: center;
    border: 1px solid #e0e1dd;
    // padding-block: 2px;
    margin-top: 33px;
    padding-block: 2px;
    border-bottom: none;
    border-left: none;
    text-align: center;
  }
}

.event-item {
  padding-right: 0 !important;
}

.iqs-calendar-container {
  .resource-table > tbody > tr > td {
    background-color: #0f1f3e;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
  }

  .timeline-event {
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    // transform: translateY(23%);
    transform: translateY(3px);
  }

  .resource-table tbody tr td[data-resource-id='comments'],
  .resource-table tbody tr td[data-resource-id='systems'],
  .resource-table tbody tr td[data-resource-id='equipment'],
  .resource-table tbody tr td[data-resource-id='other'],
  .resource-table tbody tr td[data-resource-id='person'] {
    background-color: #6ab0e2;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 16.94px;
    color: #000000;
  }
  .resource-table tbody tr td[data-resource-id*='collapsable__'] {
    background-color: #c6daf24d;
    color: #000000;
  }
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  z-index: 1000;
  font-size: 12px;
  color: #000;
  border-radius: 5px;
}
.info-icon {
  // position: static; /* Make it the context for the tooltip */
  // display: inline-block;
  height: 16px;
  width: 16px;
  font-size: 10px;
  text-align: center;
  margin-left: 10px !important;
  border-radius: 50%;
  border: solid 1.5px #0088d0;
  color: #0088d0 !important;
}

.info-icon:hover {
  .tooltip-content {
    display: block;
  }
}

.resource-table-checkbox .newui-small-checkmark {
  height: 10px !important;
  width: 10px !important;
  border-radius: 0 !important;
  color: white !important;
  border: 1px solid #fff !important;
  overflow: hidden;
  p {
    font-size: 14px !important;
  }
}
.chevron-down {
  transform: rotate(-270deg);
  cursor: pointer !important;
}

.chevron-up {
  transform: rotate(270deg);
  cursor: pointer !important;
}

.calendar-legends-container {
  width: 500px;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  color: #000 !important;
  .container-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 15px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .legend-box {
    width: 50px;
    height: 15px;
    gap: 0px;
    border-radius: 21px;
    margin-right: 16px;
    font-weight: 500;
  }

  .legend-title {
    font-weight: 600;
    font-size: 14px;
  }
}

.calendar-add-button {
  width: 150px;
}

.iqs-display-date-time {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}

.tooltip-content .card {
  background-color: #f8f8f8;
  padding: 1rem;
  width: 300px;
  // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-top-left-radius: 0;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.details {
  .heading {
    font-weight: bold !important;
    font-size: 14px !important;
    color: #000;
    margin-bottom: 10px !important;
  }
  table {
    border: none !important;
    background-color: transparent !important;
    tr {
      border: none;
    }
    td {
      border: none !important;
      padding: 5px !important;
      font-size: 12px;
    }
  }
  .person-detail {
    padding-block: 5px !important;
    font-size: 12px;
  }
}

.view-resource {
  display: block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #0088d0;
  color: #fff !important;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 112px;
}

.today-button-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 19.36px;
  padding: 0.3rem 0.75rem !important;
  border: 1px solid #e0e1dd;
  margin-inline: 0.5rem;
  color: #0088d0;
}

.request-type2-time-color {
  background-color: #cf3652;
  padding-inline: 2px;
  border-radius: 1px;
  color: #fff;
}

.iqs-calendar-active {
  .ant-popover {
    background-color: #f8f8f8;
    white-space: nowrap;
    // z-index: 1000;
    font-size: 12px;
    // color: #000 !important;
    border-radius: 5px;
    transform: translateX(20px) !important;

    .ant-popover-inner {
      background-color: #f8f8f8;
      color: #000 !important;
      min-width: 250px !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
      padding-inline: 10px;
      padding-block: 5px;
      max-width: 300px;
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
    }
  }
  .ant-popover .ant-popover-arrow {
    display: none !important;
    transform: none;
  }
  .ant-popover-inner div {
    color: #000 !important; /* Apply black color to all inner elements */
  }
  .ant-popover-inner button {
    width: 74px;
    padding: 7px 10px 7px 10px;
    color: #fff !important;
    border: none;
    background-color: #0088d0;
    font-weight: bold;
  }
}

.event-popover-container {
  .event-dot {
    width: 10px;
    height: 10px;
    margin-top: 3px;
    border-radius: 50px;
    margin-right: 10px;
  }
}

.edit-button {
  width: 74px;
  padding: 7px 10px 7px 10px;
  color: #fff !important;
  border: none;
  background-color: #0088d0;
  font-weight: bold;
}

.resource-groupname {
  font-family: 'FrutigerNextRegular', sans-serif;
}

.bullet {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 10px;
  background-color: #000;
}


.month-view-border-seprator{
  border: 1px solid #e0e1dd;
  padding-block: 2px;
  border-bottom: none;
  border-left: none;
  text-align: center;
}

.hide-border-day-week-view {
  .scheduler-bg .scheduler-bg-table tbody tr td:nth-child(odd) {
    border-right: none !important;
  }
}

.month-view-request-type1{
  background-color: #000000;
  height: 13px;
  border-radius: 30px;
}

.month-view-request-type2{
  // background: repeating-linear-gradient(-45deg, #FF0000, #FF0000 2px, #fff 2px,  #fff 5px) !important;
  background: repeating-linear-gradient(45deg, #d62828, #d62828 7px, transparent 2px, transparent 11px);
  height: 13px;
  border-radius: 30px;
}

.month-view-request-type3{
  // background-color: #FFC000;
  background: linear-gradient(to right, red 33.33%, yellow 33.33% 66.66%, green 66.66%);
  height: 13px;
  border-radius: 30px;
}


.add-location-comment-popup-width{
  max-width: 641px !important;
}

.location-comment-background{
  background-color: #dfdfe375 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.public-holiday-comment-background{
  background-color: #DCE3E9 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-event-slot{
  background-color: #4bb301 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resource-checkbox{
    width: 10px;
    height: 10px;
    border: 1px solid #FFFFFF;
    margin-right: 10px;
}
.selected-resource-checkbox{
   background-color: #FFFFFF;
}

.selected-resource{
  font-size: 14px;
  line-height: 18px;
}

.popover-icons-display{
  border: 1px solid #0088d0 !important;
}

.edit-booked-event-button{
  background-color: #fff;
  height: 38px;
  margin-top: 20px;
  border: 1px solid #0088d0 !important;
}
