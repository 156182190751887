.operators-schedule-table-container {
  overflow: auto;
}

.operator-schedule-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #e0e1dd;
  table-layout: fixed;
  word-wrap: break-word;
}

.operator-table-header {
  background-color: #f8f8f8;
  width: 100px;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #e0e1dd;
  text-align: center;
}

.operator-table-row {
  padding: 5px 16px;
  border: 1px solid #e0e1dd;
  z-index: 1;
}

.schedule-bar {
  background-color: #97b3e7;
  height: 5px;
  height: 20px;
  width: '100%';
  z-index: 2;
  width: 100.2px;
}

.operator-td {
  border: 1px solid #e0e1dd;
  // padding-block: 10px;
}

.bl-br-none{
    border-left: none;
    border-right: none;
}

.bl-none{
    border-left: none;
}

.br-none{
    border-right: none;
}

.op-name-cell {
  width: 250px;
  text-align: center;
}

.operator-td span {
  font-size: 14px;
  color: #0F1F3E;
}

.left-round-schedule-bar{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-right: 0;
}

.right-round-schedule-bar{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 0;
    width: 99px;
}

.full-round-schedule-bar{
    border-radius: 10px;
    width: 99px;
}

.fixed-header-col{
    padding-inline: 10px;
}

.operator-legend{
  height: 15px;
  width: 15px;
  border-radius: 5px;
  background-color: #97b3e7;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 2.5px;
}

.tooltip-operators {
  position: absolute;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  z-index: 3000;
  visibility: hidden;
  opacity: 0;
  width: 275px;
  white-space: nowrap;
  transition: opacity 0.3s ease;
  padding: 8px;
  bottom: 25px;
  &::after {
    content: "";
    position: absolute;
    left: 10%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
    transform: rotate(- 180deg);
    bottom: -15px;
  }
}

.tooltip-right {
  right: 0;
  &::after {
    content: "";
    position: absolute;
    left: 10%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
    transform: rotate(- 180deg);
    bottom: -15px;
    left: 90%;
  }
}

.tooltip-container:hover .tooltip-operators {
  visibility: visible;
  opacity: 1;
  padding: 8px;
}

.operator-td-empty{
  padding: 10px;
}
.schedule-bar-inactive{
  background-color: #fff;
  height: 5px;
  height: 20px;
  width: '100%';
  z-index: 2;
  margin-block: 10px;
}

::-webkit-scrollbar {
  height: 10px !important;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #a9a9a9; /* Color of the scrollbar thumb */
}

.custom-modal-width{
  max-width: 90% !important;
  margin: 1.75rem auto;
}