@import "resources/styles/variables";

.scheduler-view {
  ::-webkit-scrollbar {
    height: 15px !important;
    width: 15px;
  }
}

.resource-view {
  ::-webkit-scrollbar {
    height: 15px !important;
    width: 15px;
  }
}

.scheduler-legend {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 20px;

  .scheduler-legend-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;

    div:first-of-type {
      width: 14px;
      height: 14px;
      border-radius: 3px;
      background-color: $color-dark-blue;
      margin-right: 5px;
    }
    div:last-of-type {
      padding-top: 4px;
    }

    &.legend-delivery {
      div:first-of-type {
        background-color: $color-light-blue;
      }
    }
    &.legend-reconditioning {
      div:first-of-type {
        background-color: $color-dark-green;
      }
    }
    &.legend-twenty-four-hour-reservation {
      div:first-of-type {
        background-color: #747678;
      }
    }
    &.loan-period-overdrawn {
      div:first-of-type {
        background-color: #ff1a00;
      }
    }
  }
}

.transaction-list {
  margin-left: 14px !important;
}

.zmcc-scheduler-legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  .scheduler-legend-item {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;

    div:first-of-type {
      padding-top: 4px;
    }

    div:last-of-type {
      width: 14px;
      height: 14px;
      border-radius: 3px;
      margin-left: 5px;
    }

    &.legend-loan div:last-of-type {
      background-color: $color-dark-blue;;
    }

    &.legend-delivery div:last-of-type {
      background-color: $color-light-blue;
    }

    &.legend-confirm div:last-of-type {
      background-color: #63BA3C;
    }

    &.legend-missing div:last-of-type {
      background-color: #FF0000;
    }

    &.legend-non-issue div:last-of-type {
      background-color: #057df5;
    }

    &.legend-not-available div:last-of-type {
      background-color: #808080;
    }
  }
}

.color-box{
  width: 30px;
  height: 16px;
  border-radius: 21px;
}

.new-ui-color-legends{
  display: flex;
  gap: 15px;
  justify-content: start;
  align-items: center;
  margin-bottom: 14px;
}

.color-legend-text{
  font-size: 12px;
  font-weight: bold;
}
